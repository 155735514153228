// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infor-bar {
  background: linear-gradient(180deg, rgba(0, 132, 69, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 5px 10px;
  border-radius: 5px;
  margin: 2px;
}`, "",{"version":3,"sources":["webpack://./src/screens/Notifications/Notification.scss"],"names":[],"mappings":"AAAA;EACI,2FAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".infor-bar {\n    background: linear-gradient(180deg, rgba(0, 132, 69, 0.35) 0%, rgba(255, 255, 255, 0) 100%);\n    padding: 5px 10px;\n    border-radius: 5px;\n    margin: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
